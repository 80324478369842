/* eslint-disable react/require-default-props */
import React from 'react';

import {
  SwitchTransition, CSSTransition,
} from 'react-transition-group';

import { Breadcrumb } from 'semantic-ui-react';

export type ApplicationBreadcrumbSectionChildrenProps = {
  children?: never;
  key: string;
  libelle?: string;
};

export type ApplicationBreadcrumbSectionLibelleProps = {
  children?: React.ReactNode;
  key: string;
  libelle?: never;
};

const ApplicationBreadcrumbSection = (
  props: ApplicationBreadcrumbSectionChildrenProps | ApplicationBreadcrumbSectionLibelleProps,
) => {
  const { children, libelle } = props;

  return (
    <Breadcrumb.Section>
      <SwitchTransition>
        <CSSTransition
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          <span data-testid={`section${libelle ? (`.${libelle}`) : ''}`}>{libelle || children}</span>
        </CSSTransition>
      </SwitchTransition>
    </Breadcrumb.Section>
  );
};

export default ApplicationBreadcrumbSection;
