// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

export const authTokenEndPoint = '/api/oauth/token';
export const authTokensEndPoint = '/api/oauth/tokens/:token';
export const authAccountEndPoint = '/api/users/account';
export const authProfileEndPoint = '/api/users/profile';

export const clientId = process.env.APP_CLIENT_ID;
export const clientSecret = process.env.APP_CLIENT_SECRET;
