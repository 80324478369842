// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import {
  Grid, Header, Icon, Menu,
  Ref, SemanticICONS,
} from 'semantic-ui-react';

import './ApplicationLauncherMenuList.less';

import { ApplicationList } from '../ApplicationList';

import { useState } from '../../hooks';
import { getApplicationClassName } from '../utils/className';

const className = getApplicationClassName('launcher', 'menu', 'list');

export type ApplicationLauncherMenuListProps = {
  /** */
  header?: React.ReactNode;

  /** */
  icon?: string | React.ReactElement;

  /** */
  items?: React.ReactElement[];

  /** */
  actions?: React.ReactElement[];

  /** */
  loading?: boolean;

  /** */
  fetching?: boolean;

  /** */
  placeholder?: string;

  /** */
  only?: 'computer' | 'computer tablet' | 'tablet' | 'tablet mobile' | 'mobile';

  /** */
  sortable?: boolean;

  /** */
  onSort?: (oldIndex: number, newIndex: number) => void;
};

export type ApplicationLauncherMenuListType = React.ComponentType<ApplicationLauncherMenuListProps>;

export const ApplicationLauncherMenuList: ApplicationLauncherMenuListType = (
  props: ApplicationLauncherMenuListProps,
) => {
  const {
    header,
    icon,
    items,
    actions,
    loading,
    fetching,
    placeholder,
    only,
    sortable,
    onSort,
  } = props;

  const [position, setPosition] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [height, setHeight] = useState(0);

  const viewportRef = React.useRef<HTMLElement>(null);

  // const viewportRect = useRect(viewportRef);
  // const viewportScroll = useScroll(viewportRef);

  React.useEffect(() => {
    React.startTransition(() => {
      setHeight(viewportRef.current?.clientHeight);
    });
  }, [viewportRef.current?.clientHeight]);

  React.useEffect(() => {
    React.startTransition(() => {
      setScroll(viewportRef.current?.scrollHeight);
    });
  }, [viewportRef.current?.scrollHeight]);

  const scrollTo = React.useCallback((top) => {
    if (viewportRef) {
      const { current } = viewportRef;

      if (current) {
        current.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  }, []);

  const handleScroll = React.useCallback(({ target: { scrollTop } }) => {
    React.startTransition(() => {
      setPosition(scrollTop);
    });
  }, []);

  const handleScrollUp = React.useCallback(() => {
    if (scroll !== 0) {
      React.startTransition(() => {
        scrollTo(Math.ceil(Math.max(position - height, 0)));
      });
    }
  }, [
    scroll,
    height,
    position,
  ]);

  const handleScrollDown = React.useCallback(() => {
    if (scroll !== height) {
      React.startTransition(() => {
        scrollTo(Math.floor(Math.min(position + height, scroll)));
      });
    }
  }, [
    scroll,
    height,
    position,
  ]);

  const scrollUp = React.useMemo(() => (
    <Menu.Item
      disabled={(scroll === height) || (position === 0) || loading}
      onClick={handleScrollUp}
    >
      <Icon name="chevron up" size="big" fitted />
    </Menu.Item>
  ), [
    scroll,
    height,
    position,
    loading,
    handleScrollUp,
  ]);

  const scrollDown = React.useMemo(() => (
    <Menu.Item
      disabled={(scroll === height) || (position === scroll) || loading}
      onClick={handleScrollDown}
    >
      <Icon name="chevron down" size="big" fitted />
    </Menu.Item>
  ), [
    scroll,
    height,
    position,
    loading,
    handleScrollDown,
  ]);

  const headerMenu = React.useMemo(() => (
    <Menu borderless>
      <Menu.Menu position="left">
        <Menu.Item header>
          {React.isValidElement(icon) ? icon : icon && <Icon name={(fetching ? 'spinner' : icon) as SemanticICONS} loading={fetching} /> }
          {header}
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        {scrollUp}
      </Menu.Menu>
    </Menu>
  ), [
    icon,
    fetching,
    header,
    scrollUp,
  ]);

  const footerMenu = React.useMemo(() => (
    <Menu borderless>
      <Menu.Menu position="left">
        {actions}
      </Menu.Menu>
      <Menu.Menu position="right">
        {scrollDown}
      </Menu.Menu>
    </Menu>
  ), [
    actions,
    scrollDown,
  ]);

  const empty = React.useMemo(() => (
    <Header icon>
      {React.isValidElement(icon) ? icon : icon && <Icon className="outline" name={(icon as SemanticICONS)} />}
      {placeholder}
    </Header>
  ), [
    icon,
    placeholder,
  ]);

  const content = React.useMemo(() => (
    <ApplicationList
      items={items}
      loading={loading}
      placeholder={empty}
      sortable={sortable}
      onSort={onSort}
    />
  ), [
    items,
    loading,
    empty,
    sortable,
    onSort,
  ]);

  return React.useMemo(() => (
    <Grid.Column
      className={className}
      only={only}
    >
      <Grid.Row
        verticalAlign="top"
      >
        {headerMenu}
      </Grid.Row>
      <Ref innerRef={viewportRef}>
        <Grid.Row
          onScroll={handleScroll}
          verticalAlign="middle"
        >
          {content}
        </Grid.Row>
      </Ref>
      <Grid.Row
        verticalAlign="bottom"
      >
        {footerMenu}
      </Grid.Row>
    </Grid.Column>
  ), [
    only,
    content,
    headerMenu,
    footerMenu,
    handleScroll,
  ]);
};
