// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

export type ApplicationThemeVariant =
| 'horizon'
| 'millesime'
| 'interco';

export type ApplicationThemeContextProps = {
  /** */
  variant: ApplicationThemeVariant;

  /** */
  setVariant?: React.Dispatch<React.SetStateAction<ApplicationThemeVariant>>;
};

export const ApplicationThemeContext = React.createContext<ApplicationThemeContextProps>({
  variant: 'horizon',
  setVariant: null,
});
