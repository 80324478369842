// #region License

/**
 * @license
 * Copyright (C) Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as React from 'react';

import { Transition } from 'semantic-ui-react';

import { useConst, useEventCallback } from '@jvs-group/jvs-mairistem-tools';

import { ApplicationPageContext, ApplicationPageContextProps } from '../ApplicationPage';

import { ApplicationLoader } from '../ApplicationLoader';

import { useState } from '../../hooks';

type Components = ApplicationPageContextProps['manifest']['components'];
type Component = Components extends Record<string, infer T> ? T : never;

export type ApplicationModuleProps = {
  [key: string]: unknown;

  /** */
  name: string;

  /** */
  code: string;

  /** */
  scope: 'content' | 'header' | 'footer' | 'help';

  /** */
  container: React.MutableRefObject<HTMLElement>;
};

export type ApplicationModuleType = React.ComponentType<ApplicationModuleProps>;

export const ApplicationModule: ApplicationModuleType = (
  props: ApplicationModuleProps,
) => {
  const {
    name,
    code,
    scope,
    container,
  } = props;

  const root = container.current;

  const { manifest, error } = React.useContext(ApplicationPageContext);

  const [component, setComponent] = useState<Component>();

  const [render, setRender] = React.useState(false);
  const [timed, setTimed] = React.useState(false);

  const exception = React.useRef(null);

  const timer = React.useRef(null);

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  const routes = useConst(manifest?.menus?.map(({ code, name, path }) => ({ code, name, path })));

  const catchException = useEventCallback(({ message }) => {
    // eslint-disable-next-line no-console
    console.error(message);

    exception.current = message;
  });

  React.useEffect(() => {
    if (manifest && name) {
      React.startTransition(() => {
        setComponent(manifest.components?.[name]);
      });
    }
  }, [
    manifest,
    name,
  ]);

  React.useEffect(() => {
    if (component && root) {
      // eslint-disable-next-line no-console
      console.debug(`[ApplicationModule] {${scope}} ${name} mounted.`);
      component.mount(root, {
        scope, module: { routes },
      });

      timer.current = setTimeout(() => setTimed(true), 30000);
    }

    return () => {
      if (component) {
        // eslint-disable-next-line no-console
        console.debug(`[ApplicationModule] {${scope}} ${name} unmounted.`);
        component.unmount(root);

        clearTimeout(timer.current);
      }

      React.startTransition(() => {
        setRender(false);
        setTimed(false);
      });

      exception.current = null;
    };
  }, [
    root,
    code,
    scope,
    component,
  ]);

  React.useEffect(() => {
    if (timed) {
      throw new Error('Le chargement du module n\'a pas répondu dans la limite de temps impartie.');
    }
    if (error) {
      throw new Error('Le chargement du module a échoué.');
    }
  }, [error, timed]);

  React.useEffect(() => {
    if (!render) {
      if (exception.current) {
        throw new Error(exception.current);
      }
    }
  }, [render]);

  React.useLayoutEffect(() => {
    React.startTransition(() => {
      setRender(false);
    });

    const observer = new MutationObserver(() => {
      clearTimeout(timer.current);

      React.startTransition(() => {
        setRender(!!root.childNodes.length);
      });
    });

    if (root) {
      observer.observe(root, {
        childList: true,
      });
    }

    window.addEventListener('error', catchException);

    return () => {
      window.removeEventListener('error', catchException);

      if (root) {
        observer.disconnect();
      }
    };
  }, [root]);

  return (
    <Transition
      visible={!render}
      animation="fade"
      unmountOnHide
    >
      <ApplicationLoader type={scope === 'content' && 'overlay'} />
    </Transition>
  );
};
